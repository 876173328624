<template>
  <div id="app">
    <div class="core-page" ref="corePage">
      <router-view></router-view>
    </div>
  </div>
</template>


<script>

export default {
  name: '',
  data() {
    return {

    }
  },

  mounted() {
    // alert('统计ip访问量')
  },

  destroyed() {

  },

  methods: {



  },

  computed: {},

  watch: {}
}
</script>


<style lang="less" scoped>
#app {
  width: 100%;
  height: 100%;
  min-width: 1200px;
  //min-height: 500px;

  .core-page {
    width: 100%;
    height: 100%;
    position: relative;


  }

}

</style>
