import Vue from 'vue'

import {
  Image
} from 'element-ui'

Vue.prototype.$ELEMENT = { size: 'small' }
Vue.use(Image)


