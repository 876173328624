import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/plugins/foreignPlugins' // 按需引入饿了么组件
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/global.css'


// 视频播放器
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)




const bus = new Vue()
Vue.prototype.$bus = bus


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
